<template>
  <nav class="navbar">
    <div class="navbar-brand">
      <router-link to="/" class="brand-link">
        <v-icon name="ri-shield-line" scale="1.5" />
        Vault
      </router-link>
    </div>
    <ul class="navbar-menu">
      <li><router-link to="/about">About</router-link></li>
      <li><router-link to="/contact">Contact Us</router-link></li>
      <li><button class="trial-button">Coming Soon</button></li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'AppNavbar'
}
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
  background: white;
  margin: 1rem 0rem 1rem 1rem;
  padding: 0.5rem;
  border-radius: 7.5px;
  border: 2px solid black;
  display: flex;
  align-items: center;
  gap: 10px;
}

.navbar-menu {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 1rem;
  padding: 0.5rem;
  background: white;
  border-radius: 7.5px;
  border: 2px solid black;
}

.navbar-menu li {
  margin-left: 1rem;
}

.navbar-menu a {
  text-decoration: none;
  color: #000;
}

.brand-link {
  text-decoration: none;
  color: #333;
  display: flex;
  align-items: center;
  gap: 10px;
}

.demo-button, .trial-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.demo-button {
  background-color: transparent;
  color: #333;
}

.trial-button {
  background-color: #000;
  color: #fff;
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
  font-style: normal;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: row;
    align-items: flex-start;
  }
  
  .navbar-brand {
    font-size: 1rem;
    margin: 0.5rem;
    padding: 0.5rem;
  }
  
  .navbar-menu {
    flex-direction: column;
    align-items: flex-start;
    margin: 0.5rem;
    padding: 0.5rem;
  }

  .navbar-menu li {
    margin-left: 0;
    margin-top: 0.5rem;
  }
}
</style>
